<template>
    <b-modal
    v-model="openModal"
    :title="title"
    header-bg-variant="dark"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    >
    <div class="text-center my-3">{{message}}</div>
    <template v-slot:modal-footer>
        <div>
            <b-button variant="danger" 
                @click.prevent="$emit('close-modal')">
                Cerrar
            </b-button>
        </div>
    </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalAlert",
    props: {
        message: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        openModal: {
            type: Boolean,
            default: false
        }
    }

};

</script>
