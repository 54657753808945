<template>
<div>
    <b-row>
        <b-col cols="12" md="12">
            <b-table 
                responsive outlined
                :fields="fields" 
                :items="items">
                <template #cell(custom_column)="data">
                    <b-button pill variant="light" size="sm" 
                        @click.prevent="confirmDeleteItem(data.item)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
                <template #cell(imagen)="data">
                    <div class="image-section">
                        <b-img @click.prevent="itemInfo(data.item)" :src="data.item.imagen" :alt="data.item.nombre"></b-img>
                    </div>
                </template>
                <template  v-slot:cell(nombre)="data">
                    <b-row class="item-detail">
                        <b-col cols="12">
                            <div>
                                <b><a class="item-name" @click.prevent="itemInfo(data.item)">{{capitalizeFirstLetterOfAllWordsExceptFirst(data.item.nombre)}} </a></b>
                            </div>
                            <div class="pt-2">
                                <b>Referencia:</b> {{data.item.codigoItem}}
                            </div>
                            <div v-if="checkOptionItemExistence(data.item)">
                                <b-badge variant="danger">Disponible: {{data.item.existencia}}</b-badge>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <template  v-slot:cell(cantidad)="data">
                    <div class="item-quantity">
                    <SpinButton 
                        :value="data.item.cantidad"
                        @input="updateItemAmmountCart($event, data.item)"
                        />
                    </div>
                </template>
                <template v-slot:cell(valor_real_unit)="data">
                    <b><span class="text-nowrap price">$ {{numberFormat(data.item.valor_real_unit) }}</span></b>
                </template>
                <template v-slot:cell(porcentaje_impuesto)="data">
                    <b class="price">{{data.item.porcentaje_impuesto}} % </b>
                </template>
                <template v-slot:cell(subtotal_por_items)="data">
                <b class="text-nowrap price"> $ {{ numberFormat(data.item.subtotal_por_items_con_descuento) }}</b>
                </template>
            </b-table>
        </b-col>
        <b-col cols="12" md="12">
            <b-row class="mx-1 gap" align-h="between">
                <b-col cols="12" md="5" class="image-section p-3 text-center">
                    <div class="pb-4"><b>Comentario de la Orden:</b> <b-button variant="info" @click.prevent="$emit('open-modal-order-comment')">
                            Añadir comentario
                        </b-button> </div>
                    <div>
                        
                    </div>
                    <div v-if="comentario !== null" class="pt-1">
                        <div><b>Comentario:</b></div>
                        <div>{{comentario}}</div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="image-section p-3">
                    <div class="text-center">
                        <div>
                            <div class="total-title"> Monto Total:</div>
                            <div class="total-title pt-2">$ {{numberFormat(datoCalculos.total_precio)}} COP</div>
                        </div>
                        <div class="pt-4" >
                            <div>Subtotal Bruto: <b>$ {{numberFormat(datoCalculos.subtotal_bruto)}}</b></div>
                        </div>
                        <div v-if="datoCalculos.descuento_comercial > 0">
                            <div>Descuento: <b>$ {{numberFormat(datoCalculos.descuento_comercial)}}</b></div>
                        </div>
                        <div>
                            <div>Subtotal Neto: <b>$ {{numberFormat(datoCalculos.subtotal_neto)}}</b></div>
                        </div>
                        <div>
                            <div>IVA: <b>$ {{numberFormat(datoCalculos.impuesto)}}</b></div>
                        </div>
                        <div class="pt-4">
                            <b-button class="processOrder" variant="info" v-if="canProcessOrder" block @click.prevent="$emit('process-order')">Procesar pedido</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
        
        <DeleteItemModal 
            :modalDeleteItemFromCart="modalDeleteItemFromCart"
            :deleteItemDetail="deleteItemDetail"
            @close-modal="modalDeleteItemFromCart = false"
            @confirm-modal="deleteItemFromCart($event)"
            />
    </div>
</template>

<script>
import {  mapState, mapMutations } from 'vuex'
import DeleteItemModal from './DeleteItemModal.vue'
import SpinButton from '@/components/GlobalComponents/SpinButton.vue'
export default {
    name: "ItemsComponent",
    props: {
        items: {
            type: Array
        },
        opcionExistencia: {
            type: Number
        },
        datoCalculos: {
            type: Object
        },
        comentario: {
            type: String
        },
        canProcessOrder: {
            type: Boolean
        }
    },
    data(){
        return{
            fields: [
                { key: 'custom_column', label: 'Remover', sortable: false, tdClass: 'align-middle text-center', thClass: 'text-center'},
                { key: 'imagen', label: 'Imagen', sortable: false, tdClass: 'align-middle text-center'},
                { key: 'nombre', label: 'Item', sortable: true, thClass: 'text-center', tdClass: 'align-middle'},
                { key: 'cantidad', label: 'Cantidad', sortable: true, tdClass: 'align-middle text-center'},
                { key: 'valor_real_unit', label: 'Vr. Unit.', sortable: true, tdClass: 'align-middle text-center'},
                { key: 'porcentaje_impuesto', label: 'Imp. %', sortable: true, thClass: 'text-nowrap', tdClass: 'align-middle text-center'},
                { key: 'subtotal_por_items', label: 'Subtotal', sortable: true, tdClass: 'align-middle text-center'},
            ],
            modalDeleteItemFromCart: false,
            deleteItemDetail: {}
        }
    },
    components: {
        DeleteItemModal,
        SpinButton
    },
    computed:{
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapMutations(['setLoading']),
        checkOptionItemExistence(item){
            return (
                (item.cantidad > item.existencia && item.item_propio === 1 && this.opcionExistencia === 1) ||
                (item.cantidad > item.existencia && item.item_propio === 0)
            )
        },
        confirmDeleteItem(item){
            this.modalDeleteItemFromCart = true;
            this.deleteItemDetail = item;
        },
        async deleteItemFromCart(itemId){
           this.setLoading(true)
           this.modalDeleteItemFromCart = false;
           await this.$conectar.post('/cart/delete-item-from-cart-user', {id: itemId})
           .then(() =>{
                this.$emit('get-cart-info-and-price');
           })
           .catch(e =>{
                console.log(e.response);
                this.setLoading(false)
           })
           this.setLoading(false)
        },
        async updateItemAmmountCart(ammount, item){
            this.setLoading(true)
            let id = item.id;
            let cantidad = ammount;
            let referencia = item.codigoItem;
            let actualizar = {id, cantidad, referencia};
            await this.$conectar.post('articulos/update-item-cart', actualizar)
            .then(response => {
                if(response.data.error == false){
                    this.$emit('get-cart-info-and-price');
                }else{
                    this.$root.$bvToast.toast(response.data.mensaje, {
                        title: "Respuesta del servidor",
                        variant: "warning",
                        solid: true
                    })
                    this.$emit('get-cart-info-and-price');
                }
            })
            .catch(e => {
                console.log(e.response);
                this.$bvToast.toast('Parece que hubo un error en el servidor', {
                    title: "Respuesta del servidor",
                    variant: "danger",
                    solid: true
                })
                this.$emit('get-cart-info-and-price');
            })
            this.setLoading(false)
        },
        itemInfo(datos){
            if(datos.item_propio === 0){
                this.$router.push({ name: 'ItemDetail', params:{ referencia: datos.codigoItem} });
            }else if(datos.item_propio === 1){
                this.$router.push({ name: 'itemEmpresaDetail', params:{ codigo: datos.codigoItem} });
            }
        },
    }
};

</script>
<style scoped>
.processOrder{
    background: linear-gradient(to right, #b00b3c 50%, #138496 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    transition: all .8s ease-out;
}

.processOrder:hover {
  background-position: left bottom;
}
.image-section {
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.image-section img{
    cursor: pointer; 
    max-width: 100%; 
    max-height: 150px; 
    object-fit: scale-down;
}
.item-detail {
    min-width: 400px;
}

.item-quantity {
    min-width: 200px;
    max-width: 220px;
}

.item-name{
    font-size: 16px;
    color: #273c99 !important;
}

.price{
    color: rgb(5, 161, 5) !important;
    font-size: 18px;
}

.total-title{
    color: #313c49;
    font-size: 22px;
    font-weight: bold;
}
</style>