<template>
    <b-modal
        v-model="modalComment"
        size="md"
        header-bg-variant="dark"
        header-text-variant="light"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        >
        <template v-slot:modal-title>
            <div class="pb-2">COMENTARIOS DEL PEDIDO</div>
        </template>
        <b-row>
            <b-col cols="12">
                <p class="font-weight-medium">En el siguiente recuadro puedes ingresar un comentario abierto para tu pedido: (Máximo 150 caracteres)</p>
            </b-col>
            <b-col cols="12">
                <b-form-textarea
                    v-model="addComment" 
                    :rules="commentRules"
                    placeholder="Ingrese comentario..."
                    rows="6"
                    max-rows="6"
                    counter="150" maxlength="150"
                ></b-form-textarea>
            </b-col>
        </b-row>
        <template v-slot:modal-footer>
            <b-button variant="danger" @click.prevent="$emit('close-modal')">
                Cancelar
            </b-button>
            <b-button variant="success" @click.prevent="$emit('add-comment-to-order', addComment)">
                Guardar
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "FilterForm",
    props: {
        modalComment: {
            type: Boolean
        },
    },
    data(){
        return{
            commentRules: [v => v.length <= 150 || 'Maximo 150 caracteres'],
            addComment: '',
        }
    },
};

</script>
<style scoped>

</style>