<template>
    <b-container fluid class="py-5">
        <b-row align-h="between" class="pb-3">
            <b-col cols="12" md="4">
                <b-button variant="danger" class="button-block-mobile" @click.prevent="dropCanastaItemsModal = true"><b-icon icon="trash"></b-icon> Vaciar Canasta</b-button>
            </b-col>
        </b-row>
        <b-card-text class="pb-5">
            <ItemsComponent 
                :items="items" 
                :opcionExistencia="opcionExistencia" 
                :datoCalculos="datoCalculos"
                :comentario="comentario"
                :canProcessOrder="canProcessOrder"
                @process-order="processPedido()"
                @get-cart-info-and-price="getCartInfoAndPrice()"
                @open-modal-order-comment="modalComment = true"
                />
        </b-card-text>

        <hr />

        <itemsRecommended :items="itemsRecommended" @item-info="itemInfo($event)" @update-item-list="getCartInfoAndPrice()" />
        
        <CommentOrderModal
            :modalComment="modalComment"
            @close-modal="modalComment = false"
            @add-comment-to-order="addCommentToOrder($event)"
            />
        <ModalConfirm 
            :openModal="modalConfirmPedido" 
            title="Procesar Pedido"
            message="¿Esta seguro que desea procesar el pedido? No podra realizar cambios una vez realizado."
            @close-modal="modalConfirmPedido = false"
            @confirm-modal="procesarCanastaClienteWeb()"
            />

        <ModalAlert 
            :openModal="errorNoStock" 
            title="No hay disponibbilidad de algunos articulos"
            message="Parece que algunos de los articulos no tiene disponibilidad, por favor verifique antes de procesar el pedido"
            @close-modal="errorNoStock = false"
            />

        <ModalConfirmDropCanasta 
            message="¿Esta seguro que desea eliminar todos los items de la canasta?"
            title="Vaciar Canasta"
            :openModal="dropCanastaItemsModal"
            @close-modal="dropCanastaItemsModal = false"
            @confirm-modal="dropCanastaItems"
            />
    </b-container>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import ItemsComponent from '../components/itemsComponent.vue'
import CommentOrderModal from '../components/CommentOrderModal.vue'
import ModalAlert from '@/components/ModalAlert.vue'
import ModalConfirm from '@/components/ModalConfirm.vue'
import ModalConfirmDropCanasta from '@/components/ModalConfirm.vue'
import ItemsRecommended from '../components/RecomendedItems.vue'
export default {
    data(){
        return{
            canProcessOrder: true,
            items: [],
            datoCalculos: {},
            comentario: null,
            addComment: "",
            modalComment: false,
            opcionExistencia: 0,
            noStockItem: false,
            errorNoStock: false,
            clientFormValidateError: [],
            modalConfirmPedido: false,
            dropCanastaItemsModal: false,
            itemsRecommended: [],
        }
    },
    components: {
        ItemsComponent,
        CommentOrderModal,
        ModalAlert,
        ModalConfirm,
        ModalConfirmDropCanasta,
        ItemsRecommended
    },
    created(){
        this.getCartInfoAndPrice();
        this.recommendedItems();
    },
    methods: {
        ...mapActions('configuration', {
          _getCartCount: 'getCartItemsCountByUser',
        }), 
        ...mapMutations(['setLoading']),
        async getCartInfoAndPrice(){
            this.setLoading(true)
            await this.$conectar.post('cart/get-cart-info-and-price')
            .then(response =>{
                if(response.data.error == false){
                    this.items = response.data.cartItems;
                    this.datoCalculos = response.data.cart;
                    this.opcionExistencia = response.data.opcionExistencia;
                    if(response.data.comentario !== null){
                        this.comentario = response.data.comentario;
                        this.addComment = this.comentario;
                    }
                }else{
                    this.items = [];
                    this.datoCalculos = {};
                    this.canProcessOrder = false;
                    this.$router.push({ name: 'inicio' });
                }
                this._getCartCount();
                this.noStockItem = response.data.noStockItem;
            })
            .catch(e => {
                this.setLoading(false)
                console.log(e.response);
            })
            
            this.setLoading(false)
        },
        async addCommentToOrder(comment){
            this.setLoading(true)
            if(comment){
                this.comentario = comment;
                if (!comment.replace(/\s/g, '').length) {
                    this.comentario = null;
                }
                
            }else{
                this.comentario = null;
            }
            await this.$conectar.post('/cart/store-comment-cart', {comment: this.comentario})
                .then(response =>{
                    if(response.data.comentario !== null){
                        this.comentario = response.data.storeComment;
                        this.addComment = this.comentario;
                    }
                })
                .catch(e =>{
                    console.log(e.response);
                    this.setLoading(false)
            })
            this.modalComment = false;
            this.setLoading(false)
        },
        processPedido(){
            if(this.noStockItem === false){
                this.modalConfirmPedido = true;
            }else{
                this.errorNoStock = true;
            }
        },
        async procesarCanastaClienteWeb(){
            this.modalConfirmPedido = false;
            this.setLoading(true)
            await this.$conectar.post('cart/store-pedido-cliente-web', {comentario: this.comentario})
            .then(response => {
                if(response.data.error == false){
                    this.$root.$bvToast.toast('Su pedido ha sido registrado exitosamente', {
                        title: "Respuesta del servidor",
                        variant: "success",
                        solid: true
                    })
                }else{
                    this.$root.$bvToast.toast('Error al tratar de registrar su pedido, por favor verifique y vuelva a intentarlo.', {
                        title: "Respuesta del servidor",
                        variant: "danger",
                        solid: true
                    })
                }
                this.modalConfirmPedido = false;
                this.getCartInfoAndPrice();
            })
            .catch(e => {
                console.log(e.response);
                this.$bvToast.toast('Parece que hubo un error en el servidor', {
                    title: "Respuesta del servidor",
                    variant: "danger",
                    solid: true
                })
            })
            this.setLoading(false)
        },
         async dropCanastaItems(){
            this.dropCanastaItemsModal = false;
            this.setLoading(true)
            await this.$conectar.post('cart/drop-canasta-items')
            .then(response => {
                if(response.data.error == false){
                    this.$root.$bvToast.toast(response.data.message, {
                        title: "Respuesta del servidor",
                        variant: "success",
                        solid: true
                    })
                    this.$router.push({ name: 'inicio' });
                }else{
                    this.$root.$bvToast.toast(response.data.message, {
                        title: "Respuesta del servidor",
                        variant: "danger",
                        solid: true
                    })
                }
                this._getCartCount();
                
            })
            .catch(e => {
                console.log(e.response);
                this.$bvToast.toast('Parece que hubo un error en el servidor', {
                    title: "Respuesta del servidor",
                    variant: "danger",
                    solid: true
                })
            })
            this.setLoading(false)
            
        },
        printWindow() {
            window.print();
        },
        async recommendedItems(){
            this.setLoading(true)
            await this.$conectar.post('/cliente-web/get-recommended-items')
            .then(response =>{
                this.itemsRecommended = response.data.items;

                if (this.itemsRecommended.length === 0) {
                    return null; // Retorna null si el array está vacío
                }

            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        itemInfo(datos){
            if(typeof datos.empresa_id !== 'undefined' && datos.empresa_id != 0){
                this.$router.push({ name: 'itemEmpresaDetail', params:{ codigo: datos.codigo} });
            }else{
                this.$router.push({ name: 'ItemDetail', params:{ referencia: datos.codigo} });
            }
        },
    }
};

</script>
<style scoped>

</style>