<template>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-12 col-md-10">
                <b><span class="title">Artículos Recomendados</span></b>
            </div>
            <div class="col-12 col-md-2 align-self-end text-right">
                <b-icon
                    @click.prevent="showPrev"
                    icon="arrow-left-circle" 
                    class="icon-info" 
                    v-b-tooltip.hover>
                </b-icon> 
                |
                <b-icon
                    @click.prevent="showNext"
                    icon="arrow-right-circle" 
                    class="icon-info" 
                    v-b-tooltip.hover>
                </b-icon> 
            </div>
            <div class="col-12 pt-1">
                <VueSlickCarousel v-if="items.length" v-bind="settings" ref="carousel"> 
                    <b-card-group v-for="(item, index) of items" :key="index">
                        <b-card class="h-100">
                            <b-card-img :src="item.imagen" class="pb-0" alt="Image" @click.prevent="$emit('item-info', item)"></b-card-img>
                            <b-card-text class="card-item-name">
                                <b><a class="pt-0 item-name" @click.prevent="$emit('item-info', item)">{{capitalizeFirstLetterOfAllWordsExceptFirst(item.nombre)}}</a></b>
                            </b-card-text>
                            <b-card-text class="card-item-detail d-flex flex-column">
                                <span>
                                    <b>Referencia: </b> {{ typeof item.empresa_id !== 'undefined' && item.empresa_id != 0 ? item.codigo : item.codigo }}
                                </span>
                                <span>
                                    <b>Código de barras: </b> {{ typeof item.empresa_id !== 'undefined' && item.empresa_id != 0 ? item.codigo_barras : item.codigo_barras }}
                                </span>
                                <span>
                                    <b>Disponible: </b>
                                    <span :class="[typeof item.empresa_id !== 'undefined' && item.empresa_id != 0 ? (item.existencia >= 1 ? 'stock-available' : 'stock-low') : (item.quantity >= 1 ? 'stock-available' : 'stock-low')]">
                                        <b>{{ typeof item.empresa_id !== 'undefined' && item.empresa_id != 0 ? item.existencia : item.quantity }} En Stock</b>
                                    </span>
                                </span>
                            </b-card-text>

                            <template #footer>
                                <b class="d-flex align-items-center">
                                    <span class="item-price" v-if="typeof item.empresa_id !== 'undefined' && item.empresa_id != 0"> ${{numberFormat(item.precio)}} </span> 
                                    <span class="item-price" v-else> ${{numberFormat(item.precio_final_con_imp)}} </span> 
                                    <b-icon
                                        icon="info-circle-fill" 
                                        class="icon-info ml-2" 
                                        v-b-tooltip.hover 
                                        :title="item.taxText" 
                                        :style="cssVars"
                                        ></b-icon> 
                                    <span class="material-icons-outlined note-add-icon ml-auto add-to-cart" v-if="shouldAddItem(item)" @click.prevent="openItemModal(item)"> add_shopping_cart</span> 
                                    <span class="material-icons-outlined note-add-icon ml-auto color-block" v-else> block </span> 
                                </b>
                            </template>
                        </b-card>
                    </b-card-group>
                </VueSlickCarousel>
            </div>
        </div>
        <add-item :open-modal="modalAdd" :item="item" @close-modal="closeModal" />
    </div>
</template>

<script>
import AddItem from '@/components/AddItemToCart/AddItem.vue';
import { mapState } from 'vuex'
export default {
    name: "CardItem",
    props: {
        items: {
            type: Array
        }       
    },
    components:{
        AddItem
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('configuration', ['background', 'opcionExistencia']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
        getItemSize() {
            return this.items < 1 ? true : false;
        }
    },
    data() {
      return {
        item: {},
        modalAdd: false,
        settings: {
            slidesToShow: 4,
            rows: 1,
            accessibility: true,
            arrows: false,
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnDotsHover: true,
            pauseOnFocus: true,
            pauseOnHover: true,
            responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                }
            }]
        },
      }
    },
    methods: {
        showNext(){
            this.$refs.carousel.next()
        },
        showPrev(){
            this.$refs.carousel.prev()
        },
        shouldAddItem(item){
            if (typeof item.empresa_id !== 'undefined' && item.empresa_id !== 0) {
                if ((this.opcionExistencia === 2 && item.existencia >= 1) || this.opcionExistencia === 1) {
                    return true;
                }
            } else if (item.quantity > 0 && item.precio_final_con_imp > 0) {
                return true;
            }
            return false;
        },
        openItemModal(item){
            this.item.itemName = item.nombre;
            this.item.itemImage = item.imagen;
            if (typeof item.empresa_id !== 'undefined' && item.empresa_id !== 0){
                this.item.reference = item.codigo;
                this.item.empresa_id = item.empresa_id;
            } else {
                this.item.reference = item.codigo;
                this.item.quantity = item.quantity;
            }
            this.modalAdd = true;
        },
        closeModal(){
            this.item = {};
            this.modalAdd = false;
            this.$emit('update-item-list');
        }
    }
};

</script>
<style scoped>
.card-img { 
    max-width: 100%; 
    max-height: 150px;
    object-fit: scale-down;
    cursor: pointer;
}
.card-body{
    padding-bottom: 0px;
}

.card{
    border-radius: 0px;
}

.card-text {
    font-size: 20px;
}
.producto-agotado{
    font-size: 18px;
    color: red;
}
.icon-info{
    color: var(--border-bottom);
    cursor: pointer;
}

.title{
    font-size: 23px;
    color: #2b4861;
}

.card-footer{
    background-color: white;
    border-top: none
}

.card-group{
    height: 375px;
    margin: 0px;
}

.card-item-name{
    min-height: 40px !important;
}

.card-item-detail{
    font-size: 14px;
}

.item-price{
    color: rgb(5, 161, 5) !important;
    font-size: 18px;
}
.item-name{
    font-size: 14px;
    color: #273c99 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.slick-prev:before, .slick-next:before {
  color: #09529b !important;
}

@media (max-width: 1024px) {
    .card-group{
        height: 350px !important;
    }
    .title{
        font-size: 18px;
    }
    .card-item-detail{
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .title{
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .title{
        font-size: 16px !important;
    }
}


</style>