<template>
    <b-modal
    v-model="modalDeleteItemFromCart"
    title="Eliminar item del carrito de compra"
    header-bg-variant="dark"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="lg"
    >
    <div>
        ¿En realidad desea eliminar del carrito el producto de referencia: <b>{{deleteItemDetail.codigoItem}}</b>
    </div>
    <div>
        nombre: <b>{{deleteItemDetail.nombre}}</b> ?
    </div>
    <template v-slot:modal-footer>
        <b-button size="sm" variant="danger" @click.prevent="$emit('close-modal')">
                Cancelar
        </b-button>
        <b-button size="sm" variant="success" @click.prevent="$emit('confirm-modal', deleteItemDetail.id)">
                Confirmar
        </b-button>
    </template>
    </b-modal>
</template>

<script>
export default {
    name: "ChangeCategoryModalConfirm",
    props: {
        deleteItemDetail: {
            type: Object
        },
        modalDeleteItemFromCart: {
            type: Boolean,
            default: false
        }
    }

};

</script>
